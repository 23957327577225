import React from "react"
import i18next from '../i18n/config';


import { Link, Trans, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import ImgCoffeeR from "../img/B_nasza_kawa_3/rozpuszczalna_stosik.png";
import ImgCoffeeZ from "../img/B_nasza_kawa_3/ziarno_stosik.png";
import ImgCoffeeM from "../img/B_nasza_kawa_3/mielona_stosik.png";

export default function Header(props) {



    return (
            <React.Fragment>
                <section id="ourcoffees" class="ourcoffees firstSection">
                    <h1>{i18next.t('OurCoffeeH1')}</h1>
                    <p>{i18next.t('OurCoffeeP')}</p>

                    <div class="icons">
                        <Link to={'/OurCoffees/InstantCoffee'}>
                        <div class="icon">
                            <div><img loading="lazy" src={ImgCoffeeR} alt="Instanta Our Coffee" /></div>
                            <p>{i18next.t('OurCoffeeR')}</p>

                        </div>
                        </Link>

                        <Link to={'/OurCoffees/CoffeeBeans'}>
                        <div class="icon">
                            <div><img loading="lazy" src={ImgCoffeeZ} alt="Instanta Our Coffee" /></div>
                            <p>{i18next.t('OurCoffeeZ')}</p>
                        </div>
                        </Link>
                        <Link to={'/OurCoffees/GroundCoffee'}>
                        <div class="icon">
                            <div><img loading="lazy" src={ImgCoffeeM} alt="Instanta Our Coffee" /></div>
                            <p>{i18next.t('OurCoffeeM')}</p>
                        </div>
                        </Link>
                    </div>

                </section>


            </React.Fragment>

            )


}